.main-products-hero-section {
    margin-top: 5rem;
    padding-bottom: 7rem;
}

.products-hero-section-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    max-width: 76rem;
    width: 100%;
    margin: auto;
    padding: 1%;
}

.products-hero-section-badge {
    background-color: #62C7A321;
    color: #5BC0B3;
    border-radius: 1.5rem;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-16);
    line-height: 1.5rem;
    text-align: center;
}

.products-hero-section-title {
    font-family: var(--default-font-family-Syne);
    font-size: 4.5rem;
    font-weight: 600;
    line-height: 4.625rem;
    text-align: center;
    color: var(--color-white);
    margin: 0;
    padding: 0;
}

.products-hero-section-text {
    margin-top: 1.5rem;
    font-family: var(--default-font-family-Dm-Sans);
    font-weight: 400;
    font-size: var(--default-font-size-18);
    line-height: 1.688rem;
    text-align: center;
    color: var(--color-white);
}