.blog-page-main-section {
  background-color: var(--color-black);
  height: 100%;
  width: 100%;
}

.blog-page-header-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7.375rem 4rem;
}

.blog-page-title-container {
  width: 100%;
  max-width: 76rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.blog-page-title-container span {
  font-family: var(--default-font-family-Syne);
  font-weight: 600;
  font-size: var(--default-font-size-18);
  line-height: 1.35rem;
  color: var(--color-dark-green);
}

.blog-page-title-container .blog-page-title {
  font-family: var(--default-font-family-Syne);
  font-weight: 600;
  font-size: 4.5rem;
  line-height: 4.625rem;
  color: var(--color-white);
  margin-bottom: 0;
}

.blog-page-title-text {
  display: flex;
  gap: 3.75rem;
}

.blog-page-title-text p {
  margin-bottom: 0;
  width: 30.25rem;
  font-family: var(--default-font-family-Inter);
  font-size: var(--default-font-size-18);
  font-weight: 400;
  line-height: 1.688rem;
  color: var(--color-white);
}

.blog-page-feature-section {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #262626;
  padding: 3.75rem 7rem;
}

.blog-page-feature-card {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
}

.blog-feature-image-left img {
  width: 32.188rem;
  height: 21.5rem;
  border-radius: 0.875rem;
}

.blog-feature-content-right {
  width: 100%;
  max-width: 41.313rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.blog-feature-content-right span {
  font-family: var(--default-font-family-Syne);
  font-weight: 600;
  font-size: var(--default-font-size-18);
  line-height: 21.6px;
  color: var(--color-dark-green);
}

.blog-page-feature-card--title {
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
}

.blog-page-feature-card--title h3 {
  margin-bottom: 0;
  font-family: var(--default-font-family-Syne);
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: var(--color-white);
}

.blog-page-feature-card--title p {
  margin-bottom: 0;
  font-family: var(--default-font-family-Dm-Sans);
  font-weight: 400;
  font-size: var(--default-font-size-16);
  line-height: 1.5rem;
  color: var(--color-gray-100);
}

.read-more--button {
  border-radius: 0.5rem;
  border: 1px solid #262626;
  padding: 0.875rem 1rem;
  background-color: #141414;
  width: 100%;
  max-width: 8.313rem;
  font-size: var(--default-font-size-14);
  font-weight: 400;
  line-height: 1.313rem;
  font-family: var(--default-font-family-Dm-Sans);
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: var(--gradient-green-teal);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blog-page-all-posts {
  border-top: 1px solid #262626;
  padding: 3.75rem 7rem;
}

.blog-page-all-posts h2 {
  font-family: var(--default-font-family-Syne);
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.4rem;
  text-align: center;
  color: var(--color-white);
}
