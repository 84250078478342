.series-ai-section-container {
    width: 100%;
    height: 100%;
    background-color: var(--color-gray-50);
    padding: 6.25rem 7rem;
}

.series-ai-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5.875rem;
    width: 100%;
    max-width: 76rem;
    margin: auto;
}

.series-ai-left-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 36.875rem;
}

.series-ai-title-section {
    font-family: var(--default-font-family-Syne);
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.4rem;
    color: var(--color-gray-900);
}

.series-ai-paragrapgh-content {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-16);
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--color-gray-600);
}

.series-ai-features-data {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.feature-text {
    font-family: var(--default-font-family);
    font-weight: 400;
    font-size: var(--default-font-size-16);
    line-height: 1.5rem;
    color: var(--color-gray-700);
}

.series-ai-button-section {
    display: flex;
}

.series-ai-button-section Button:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.375rem;
    background: linear-gradient(90deg, #ff736b 0%, #fda269 100%);
    font-family: var(--default-font-family);
    font-size: var(--default-font-size-14);
    color: var(--color-white);
    text-align: center;
    border-radius: 0.5rem;
    line-height: 1.25rem;
    padding: 0 1.25rem 0;
    font-weight: 600;
    height: 3.5rem;
    cursor: default;
}

.series-ai-button-section Button:nth-child(1) img {
    width: 1rem;
    height: 1rem;
}

.series-ai-button-section Button:nth-child(2) {
    background: linear-gradient(90deg, #ff736b 0%, #fda269 100%);
    font-family: var(--default-font-family);
    font-weight: 600;
    font-size: var(--default-font-size-14);
    line-height: 1.25rem;
    text-align: center;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: default;
}

.series-ai-right-image-container {
    position: relative;
}

.series-ai-top-right-card {
    position: absolute;
    top: 3.722rem;
    left: 17.807rem;
    width: 16.313rem;
    padding: 0.938rem;
    border-radius: 0.5rem;
    background-color: var(--color-white);
    box-shadow: rgba(99, 99, 99, 0.2) 0 0.125rem 0.5rem 0;
}

.series-ai-file-container {
    display: flex;
    justify-content: space-between;
    border: 1px dashed #afb2b3;
    border-radius: 0.375rem;
    width: 14.375rem;
}

.series-ai-file-container .top-right-card-file-icon {
    background-color: #f8f8f8;
    border-top-left-radius: 0.313rem;
    border-bottom-left-radius: 0.313rem;
    padding: 1rem 1.063rem;
}

.top-right-generate-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-right: 0.6rem;
}

.top-right-card-generate-button {
    padding: 0 0.75rem 0;
    background-color: transparent;
    border-radius: 0.5rem;
    border: 1px solid #f0f0f1;
    padding: 0.25rem 0.75rem;
}

.top-right-card-generate-button span {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.975rem;
    text-align: center;
    color: #060d11;
}

.series-ai-center-left-card {
    position: absolute;
    top: 10.313rem;
    left: -3.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.859rem;
    background-color: var(--color-white);
    width: 13.313rem;
    height: 11.188rem;
    border-radius: 0.25rem;
    padding: 0.806rem 1.073rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0 0.125rem 0.5rem 0;
}

.center-left-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.center-left-card-text {
    font-size: 0.537rem;
    line-height: 0.644rem;
    font-weight: 600;
    font-family: var(--default-font-family-Figtree);
    margin-top: 0;
    margin-bottom: 0;
}

.progress-container {
    width: 11.188rem;
    height: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.125rem;
    margin-top: 0.625rem;
}

.progress-bar {
    width: 2rem;
    height: 0.188rem;
    background-color: #F0F0F1;
    border-radius: 0.188rem;
}

.progress-bar.active {
    background-color: #FE846A;
}

.center-left-card-document-type {
    display: flex;
    flex-direction: column;
    gap: 0.323rem;
    width: 11.223rem;
    height: 4.722rem;
}

.center-left-card-document-type .document-type-text {
    font-family: var(--default-font-family-Figtree);
    font-weight: 600;
    font-size: 0.429rem;
    line-height: 0.516rem;
    color: var(--color-gray-900);
    margin-bottom: 0;
}

.center-left-card-document-type .discount-rate-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--default-font-family-Figtree);
    font-weight: 600;
    font-size: 0.429rem;
    line-height: 0.516rem;
    color: #060D11;
    border: 0.027rem solid #060D11;
    padding: 0.644rem;
    background-color: #F8F8F8;
}

.center-left-card-document-type .valuation-cap-button {
    background-color: transparent;
    border: 0.027rem solid #F0F0F1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--default-font-family-Figtree);
    font-weight: 600;
    font-size: 0.429rem;
    line-height: 0.516rem;
    color: #060D11;
    padding: 0.644rem;
}

.center-left-card-continue-button {
    background: linear-gradient(90deg, #FF706B 0%, #FDA368 100%);
    font-family: var(--default-font-family-Figtree);
    font-weight: 600;
    font-size: 0.376rem;
    line-height: 0.537rem;
    color: var(--color-white);
    text-align: center;
}

.series-ai-bottom-righ-card {
    position: absolute;
    top: 26.54rem;
    left: 12.25rem;
    background-color: var(--color-white);
    width: 19.69rem;
    height: 10.44rem;
    padding: 1.186rem 1.581rem;
    gap: 1.265rem;
    border-radius: 0.316rem;
    box-shadow: 0px 4px 4px -4px rgba(12, 12, 13, 0.05), 0px 16px 16px -8px rgba(12, 12, 13, 0.1);
}

.series-ai-data-driven-container {
    display: flex;
    flex-direction: column;
    gap: 0.632rem;
}

.series-ai-data-driven-container span {
    font-family: var(--default-font-family-Figtree);
    font-size: 0.632rem;
    font-weight: 400;
    line-height: 0.886rem;
    text-align: left;
    color: #4A4A4A;
}

.series-ai-data-driven-header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.series-ai-data-driven-header-container h4 {
    font-family: var(--default-font-family-Figtree);
    font-size: 0.791rem;
    font-weight: 600;
    line-height: 0.949rem;
    text-align: left;
    margin: 0;
    padding: 0;
    color: #060D11;
}

.series-ai-data-driven {
    display: flex;
    gap: 0.632rem;
    align-items: center;
}

.series-ai-data-driven img {
    width: 0.949rem;
    height: 0.949rem;
}

.close-icon-series-ai {
    width: 1rem;
    height: 1rem;
}

@media (max-width: 1200px) {
    .series-ai-container {
        flex-direction: column;
        align-items: center;
        gap: 3rem;
    }

    .series-ai-left-content {
        width: 100%;
        padding: 0 2rem;
        text-align: center;
    }

    .series-ai-title-section {
        font-size: 1.75rem;
        line-height: 2.2rem;
    }

    .series-ai-paragrapgh-content {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

@media (max-width: 992px) {
    .series-ai-container {
        padding: 0 1rem;
    }

    .series-ai-left-content {
        padding: 0 1.5rem;
    }

    .series-ai-title-section {
        font-size: 1.5rem;
    }

    .series-ai-paragrapgh-content {
        font-size: 0.9rem;
    }

    .series-ai-features-data {
        gap: 0.5rem;
    }
}

@media (max-width: 768px) {
    .series-ai-section-container {
        padding: 3rem 1.5rem;
    }

    .series-ai-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .series-ai-left-content {
        width: 100%;
    }

    .series-ai-title-section {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    .series-ai-paragrapgh-content {
        font-size: 0.85rem;
    }

    .series-ai-features-data {
        flex-direction: column;
        align-items: flex-start;
    }

    .series-ai-right-image-container {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .series-ai-section-container {
        padding: 2rem 1rem;
    }

    .series-ai-left-content {
        padding: 0;
        gap: 1rem;
    }

    .series-ai-title-section {
        font-size: 1rem;
    }

    .series-ai-paragrapgh-content {
        font-size: 0.8rem;
    }

    .series-ai-button-section {
        flex-direction: column;
        align-items: center;
    }

    .series-ai-features-data {
        gap: 0.25rem;
    }
}