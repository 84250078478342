.federal-family-section {
    background-color: var(--color-gray-50);
    padding: 1.5rem 7rem;
}

.federal-family-title-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 76rem;
    margin: auto;
}

.federal-family-heading {
    font-family: var(--default-font-family-Syne);
    font-weight: 600;
    font-size: 2rem;
    line-height: 4.625rem;
    text-align: center;
    color: var(--color-black);
    margin-bottom: 0;
}
.federal-family-text {
    font-family: var(--default-font-family-Dm-Sans);
    font-weight: 400;
    font-size: var(--default-font-size-16);
    line-height: 1.5rem;
    text-align: center;
    color: var(--color-gray-600);
    width: 100%;
    max-width: 50rem;
    margin: auto;
}