.home-jurist-ai-values-container {
  background-image: url('@assets/images/backgrounds/mask-group-jurist-ai-values.png');
  background-repeat: no-repeat;
  background-size: 100% 75%;
  background-position: bottom;
}

#juristai-values {
  text-align: center;
  color: white;
  padding: 6.25rem 7rem 10rem 7rem;
  opacity: 1;
}

#juristai-values h1 {
  font-family: var(--default-font-family-Syne);
  font-size: 3rem;
  font-weight: 600;
  line-height: 4.625rem;
  margin-bottom: 1.25rem;
}

#juristai-values p {
  font-size: 1.2em;
  margin-bottom: 2.5rem;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 76rem;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-18);
  font-weight: 400;
  line-height: 1.688rem;
  text-align: center;
  margin-bottom: 4rem;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18.75rem, 1fr));
  gap: 20px;
  max-width: 76rem;
  margin: auto;
}

.value-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  max-width: 24.5rem;
  min-height: 21.875rem;
  padding: 2.5rem;
  gap: 30px;
  border-radius: 12px;
  border: 1px solid var(--color-green-20);
}

.value-card h2 {
  font-family: var(--default-font-family-Syne);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  margin-bottom: 10px;
  width: fit-content;
  background: linear-gradient(95.75deg, #ebf1ff 37.28%, #cedeb3 64.87%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.value-card span {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-18);
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: var(--color-gray-200);
}

.value-card .jurist-icon-container {
  margin-bottom: 30px;
}

@media screen and (max-width: 900px) {
  #juristai-values {
    padding: 100px 5% 160px 5%;
  }
}

@media screen and (max-width: 768px) {
  #juristai-values h1 {
    font-size: 2.25rem;
    line-height: 54px;
  }

  #juristai-values p {
    font-size: var(--default-font-size-16);
    line-height: 24px;
    padding: 0 20px;
  }

  .values-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
  }

  .value-card {
    padding: 20px;
  }

  .value-card h2 {
    font-size: 1.25rem;
    line-height: 24px;
  }

  .value-card span {
    font-size: var(--default-font-size-16);
    line-height: 24px;
  }
}

@media screen and (max-width: 480px) {
  #juristai-values {
    padding: 50px 20px;
  }

  .value-card {
    padding: 20px;
    min-height: auto;
  }

  .jurist-icon-container {
    display: none;
  }
}
