.blog-post-main-section-container {
  width: 100%;
  height: 100%;
  background-color: var(--color-black);
}

.blog-post-hero-section-image {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.blog-post-hero-section-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-post-hero-title {
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.blog-post-hero-title h1 {
  text-align: center;
  color: var(--color-white);
  font-family: var(--default-font-family-Syne);
  font-weight: 600;
  font-size: 3rem;
  line-height: 4.625rem;
}

.blog-post-content-container {
  display: flex;
  justify-content: center;
  color: var(--color-white);
  border-top: 1px solid #262626;
}

.blog-post-left-content {
  width: 100%;
  height: 67.625rem;
  display: flex;
  flex-direction: column;
}

.blog-post-title-section {
  height: 12.688rem;
  padding: 3.75rem 3.75rem 3.75rem 7rem;
  border-bottom: 1px solid #262626;
}

.blog-post--title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.blog-post-title-section h1 {
  font-size: 1.5rem;
  font-family: var(--default-font-family-Syne);
  font-weight: 600;
  color: var(--color-white);
  line-height: 28.8px;
  margin-bottom: 0;
}

.blog-post-title-section p {
  max-width: 43.625rem;
  font-size: var(--default-font-size-16);
  font-weight: 400;
  font-family: var(--default-font-family-Dm-Sans);
  line-height: 1.5rem;
  color: var(--color-grey-400);
  margin-bottom: 0;
}

.blog-post-content-section {
  height: 54.938rem;
  overflow-y: auto;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  padding: 3.75rem 3.75rem 3.75rem 7rem;
}

.legal-industry--title {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  margin-top: 1.8rem;
}

.legal-industry--title h2 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8rem;
  font-family: var(--default-font-family-Syne);
  color: var(--color-white);
  margin-bottom: 0;
}

.legal-industry--title .paragraph--three {
  margin-top: 1rem;
}

.blog-post-content-section p {
  width: 100%;
  max-width: 43.625rem;
  font-size: var(--default-font-size-16);
  font-weight: 400;
  font-family: var(--default-font-family-Dm-Sans);
  line-height: 1.5rem;
  color: var(--color-grey-400);
  margin-bottom: 0;
}

.blog-post-right-content {
  border-left: 1px solid #262626;
  width: 100%;
  max-width: 35.625rem;
}

.custom-icons {
  padding: 2.5rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: start;
  border-bottom: 1px solid #262626;
}

.images--section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem 0.875rem;
  background-color: #141414;
  color: #98989a;
  border-radius: 6.25rem;
  border: 1px solid #262626;
}

.table-of-content--main {
  padding: 3.75rem 7rem 3.75rem 3.75rem;
}

.table-of-content {
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
}

.table-of-content h4 {
  font-family: var(--default-font-family-Syne);
  font-weight: 600;
  font-size: var(--default-font-size-18);
  line-height: 21.6px;
  color: var(--color-grey-400);
}

.content-points--section {
  background-color: #1a1a1a;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.625rem;
  padding: 1.125rem;
  width: 100%;
  max-width: 24.875rem;
}

.content--items {
  margin-left: 1.5rem;
  font-family: var(--default-font-family-Dm-Sans);
  font-weight: 400;
  font-size: var(--default-font-size-14);
  line-height: 1.5rem;
  color: var(--color-gray-100);
}

.blog-post-similar-news-container {
    border-top: 1px solid #262626;
    padding: 3.75rem 7rem;
}

.similar-new-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 100%;
    max-width: 76rem;
}

.similar-new-title h3 {
    font-family: var(--default-font-family-Syne);
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 28.8px;
    color: var(--color-white);
}