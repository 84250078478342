.custom-drawer-main-container {
    min-width: 18.75rem;
    width: 100%;
    height: 100%;
    padding: 2rem;
    font-family: var(--default-font-family);
    background-color: var(--color-white);
}

.custom-drawer-heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.75rem;
}

.custom-drawer-heading-container h6 {
    color: var(--color-gray-900);
    margin: 0;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: left;
}

.custom-drawer-heading-container img {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}

.drawer-child-container {
    overflow-y: auto;
    scrollbar-width: thin; 
    scrollbar-color: var(--color-gray-800) transparent;
    scroll-behavior: smooth;
    height: 100%;
    max-height: calc(100vh - 7.75rem) 
}