.litig-ai-section-container {
    width: 100%;
    height: 100%;
    background-color: var(--color-gray-50);
    padding: 6.25rem 7rem;
}

.litig-ai-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5.875rem;
    width: 100%;
    max-width: 76rem;
    margin: auto;
}

.litig-ai-left-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 36.875rem;
}

.litig-ai-title-section {
    font-family: var(--default-font-family-Syne);
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.4rem;
    color: var(--color-gray-900);
}

.litig-ai-paragrapgh-content {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-16);
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--color-gray-600);
}

.litig-ai-features-data {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.feature-text {
    font-family: var(--default-font-family);
    font-weight: 400;
    font-size: var(--default-font-size-16);
    line-height: 1.5rem;
    color: var(--color-gray-700);
}

.litig-ai-button-section {
    display: flex;
}

.litig-ai-button-section Button:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.375rem;
    background: linear-gradient(90deg, #173b9a 0%, #101b7c 100%);
    font-family: var(--default-font-family);
    font-size: var(--default-font-size-14);
    color: var(--color-white);
    text-align: center;
    border-radius: 0.5rem;
    line-height: 1.25rem;
    padding: 0 1.25rem 0;
    font-weight: 600;
    height: 3.5rem;
    cursor: default;
}

.litig-ai-button-section Button:nth-child(1) img {
    width: 1rem;
    height: 1rem;
}

.litig-ai-button-section Button:nth-child(2) {
    background: linear-gradient(90deg, #173b9a 0%, #101b7c 100%);
    font-family: var(--default-font-family);
    font-weight: 600;
    font-size: var(--default-font-size-14);
    line-height: 1.25rem;
    text-align: center;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ligit-ai-right-image-container {
    position: relative;
}

.ligit-ai-top-right-card {
    position: absolute;
    min-width: 16.375rem;
    top: 8%;
    left: 63%;
    background: var(--color-white);
    padding: 0.97875rem;
    gap: 0.97875rem;
    border-radius: 0.489375rem;
    box-shadow: rgba(9, 30, 66, 0.25) 0 0.25rem 0.5rem -0.125rem, rgba(9, 30, 66, 0.08) 0 0 0 0.0625rem;
}

.ligit-ai-file-container {
    display: flex;
    align-items: center;
    padding: 0.326rem;
    border: 0.0575rem dashed var(--color-gray-200);
    border-radius: 0.244rem;
    gap: 0.5rem;
}

.ligit-ai-top-right-card-text {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: 0.570625rem;
    font-weight: 400;
    line-height: 0.815625rem;
    letter-spacing: 0.017312rem;
    text-align: left;
}

.ligit-ai-summarize-button {
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    width: 4.28875rem;
    height: 1.305rem;
    padding: 0.1875rem;
    gap: 0.32625rem;
    margin-left: 0.625rem;
    border-radius: 0.32625rem;
    border: 0.040625rem solid #0E6DD8;
    font-family: var(--default-font-family-Dm-Sans);
    font-size: 0.489rem;
    font-weight: 500;
    line-height: 0.73375rem;
    text-align: center;
    color: var(--color-gray-900);
}

.ligit-ai-summarize-button img {
    width: 0.662rem;
    height: 0.751rem;
}

.litig-ai-left-center-card {
    position: absolute;
    background-color: var(--color-white);
    width: 10rem;
    top: 10.3375rem;
    left: -15%;
    padding: 0.75625rem 1.008125rem 0.75625rem 1.008125rem;
    gap: 0.80625rem;
    border-radius: 0.201875rem;
    box-shadow: rgba(9, 30, 66, 0.25) 0 0.25rem 0.5rem -0.125rem, rgba(9, 30, 66, 0.08) 0 0 0 0.0625rem;
}

.litig-ai-data-driven-container span {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: 0.403125rem;
    font-weight: 400;
    line-height: 0.605rem;
    text-align: left;
    color: var(--Grays-700, #344054);
}

.litig-ai-data-driven {
    display: flex;
    gap: 0.9375rem;
    align-items: center;
}

.litig-ai-data-driven .atticus-motion-icons {
    width: 9.68px;
    height: 9.68px;
}

.litig-ai-data-driven-container {
    display: flex;
    flex-direction: column;
    gap: 0.3025rem;
}

.litig-ai-data-driven-header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.litig-ai-data-driven-header-container h4 {
    font-family: Inter;
    font-size: 0.50375rem;
    font-weight: 500;
    line-height: 0.61rem;
    text-align: left;
    margin: 0;
    padding: 0;
}

.litig-ai-data-driven img {
    width: 0.662rem;
    height: 0.751rem;
}

.litig-ai-bottom-right-card {
    position: absolute;
    max-width: 19.688rem;
    min-height: 11.464rem;
    width: 100%;
    top: 26.125rem;
    left: 12.5rem;
    padding: 1.18625rem 1.58125rem 1.18625rem 1.58125rem;
    gap: 1.265rem;
    border-radius: 0.31625rem;
    background-color: #f9fafb;
    box-shadow: rgba(99, 99, 99, 0.2) 0 0.125rem 0.5rem 0;
}

.litig-ai-bottom-right-card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
}

.litig-ai-bottom-right-card-heading h4 {
    font-family: var(--default-font-family-Inter);
    font-size: 0.79125rem;
    font-weight: 500;
    line-height: 0.956875rem;
    text-align: left;
    margin: 0;
}

.litig-ai-close-icon {
    height: 1rem;
    width: 1rem;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.litig-ai-bottom-right-card-body textarea {
    max-width: 16.525rem;
    width: 100%;
    min-height: 3.558125rem;
    padding: 0.6325rem;
    gap: 0.395625rem;
    border-radius: 0.31625rem;
    border: 0.039375rem solid var(--color-gray-200);
    font-family: var(--default-font-family-Dm-Sans);
    font-size: 0.6325rem;
    font-weight: 400;
    line-height: 0.94875rem;
    text-align: left;
    outline: none;
    transition: outline-color 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 0.1rem;
}

.litig-ai-bottom-right-card-body textarea:focus {
    outline: 0.125rem solid var(--color-grey-400);
    box-shadow: 0 0 0 0.25rem rgba(94, 82, 82, 0.2);
}

.litig-ai-bottom-right-card-body textarea::placeholder {
    color: var(--color-grey-400);
    font-family: DM Sans;
    font-size: 0.6325rem;
    font-weight: 400;
    line-height: 0.94875rem;
    text-align: left;
}

.litig-ai-bottom-right-card-body Button {
    background: linear-gradient(90deg, #173C9B 0%, #0F197A 100%);
    color: white;
    font-family: var(--default-font-family);
    font-weight: 600;
    font-size: 0.554rem;
    line-height: 0.791rem;
    height: 2.214rem;
    border-radius: 0.316rem;
}


@media (max-width: 1200px) {
    .litig-ai-container {
        flex-direction: column;
        align-items: center;
        gap: 3rem;
    }

    .litig-ai-left-content {
        width: 100%;
        padding: 0 2rem;
        text-align: center;
    }

    .litig-ai-title-section {
        font-size: 1.75rem;
        line-height: 2.2rem;
    }

    .litig-ai-paragrapgh-content {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

@media (max-width: 992px) {
    .litig-ai-container {
        padding: 0 1rem;
    }

    .litig-ai-left-content {
        padding: 0 1.5rem;
    }

    .litig-ai-title-section {
        font-size: 1.5rem;
    }

    .litig-ai-paragrapgh-content {
        font-size: 0.9rem;
    }

    .litig-ai-features-data {
        gap: 0.5rem;
    }
}

@media (max-width: 768px) {
    .litig-ai-section-container {
        padding: 3rem 1.5rem;
    }

    .litig-ai-container {
        flex-direction: column;
    }

    .litig-ai-left-content {
        width: 100%;
    }

    .litig-ai-title-section {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    .litig-ai-paragrapgh-content {
        font-size: 0.85rem;
    }

    .litig-ai-features-data {
        flex-direction: column;
        align-items: flex-start;
    }

    .litig-ai-right-image-container {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .litig-ai-section-container {
        padding: 2rem 1rem;
    }

    .litig-ai-left-content {
        padding: 0;
        gap: 1rem;
    }

    .litig-ai-title-section {
        font-size: 1rem;
    }

    .litig-ai-paragrapgh-content {
        font-size: 0.8rem;
    }

    .litig-ai-button-section {
        flex-direction: column;
        align-items: center;
    }

    .litig-ai-features-data {
        gap: 0.25rem;
    }
}
