.dashboard-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20.938rem, 1fr));
  gap: 1.5rem;
}

.dashboard-card {
  position: relative;
  background-color: white;
  border-radius: 0.125rem 0.75rem 0.75rem 0.125rem;
  padding: 1.5rem 2.375rem 1.5rem 2.375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 12.688rem;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboard-card-link {
  text-decoration: none;
  color: inherit;
}

.dashboard-card-link:hover .dashboard-card {
  transform: translateY(-10px);
  box-shadow: 0px 0.625rem 1.25rem rgba(0, 0, 0, 0.1);
}

.dashboard-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0.875rem;
  height: 100%;
  background: var(--gradient-color);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  transition: transform 0.3s ease;
}

.dashboard-card:hover::before {
  transform: scaleY(1.05);
}

.dashboard-card .icon-title-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 1;
}

.dashboard-card .icon-container {
  padding: 0.5rem;
}
.title-container {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  align-items: flex-start;
}

.dashboard-card .title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-gray-900);
  line-height: 1.8rem;
  font-family: var(--default-font-family-Syne);
}

.dashboard-card .badge-container {
  background-color: var(--color-light-green);
  color: #0f4900;
  padding: 0.5rem;
  border-radius: 1.4rem;
  font-weight: 400;
  line-height: 0.938rem;
  font-size: 0.625rem;
  font-family: var(--default-font-family-Dm-Sans);
  cursor: default !important;
}

.dashboard-pannel-link {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  text-decoration: none;
}

.dashboard-pannel-link .bottom-text-practice-area {
  margin-bottom: 0.625rem;
  font-size: var(--default-font-size-16);
  line-height: 1.5rem;
  font-weight: 500;
  color: var(--color-gray-800);
  font-family: var(--default-font-family-Dm-Sans);
  border-bottom: 1px solid var(--color-gray-800);
}

.dashboard-pannel-link img {
  margin-bottom: 0.5rem;
}
