.contact-sales-section-container {
  background:
    url('@assets/images/backgrounds/left-ellips.svg') no-repeat,
    url('@assets/images/backgrounds/right-ellips.svg') no-repeat,
    var(--gradient-dark-overlay);
  background-repeat: no-repeat;
  background-size: contain, contain, cover;
  background-position: top left, bottom right, center;

}

.contact-sales-section-container--height {
  height: 100vh;
}

.contact-sales--main {
  margin-top: 9.5rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
}

.contact-sales--title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  max-width: 76rem;
}

.contact-sales--title span {
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  background: #62c7a321;
  color: #5bc0b3;
  font-family: var(--default-font-family-Dm-Sans);
  font-weight: 400;
  font-size: var(--default-font-size-16);
  line-height: 1.5rem;
  text-align: center;
}

.contact-sales--title h1 {
  font-family: var(--default-font-family-Syne);
  font-weight: 600;
  font-size: 4.5rem;
  line-height: 4.625rem;
  text-align: center;
  color: var(--color-white);
  margin-bottom: 0;
}

.contact-sales--main p {
  font-family: var(--default-font-family-Dm-Sans);
  font-weight: 400;
  font-size: var(--default-font-size-18);
  line-height: 1.688rem;
  text-align: center;
  color: var(--color-white);
  margin-bottom: 0;
}

.contact-sales--button {
  font-weight: 700;
  font-size: var(--default-font-size-16);
  line-height: 1.5rem;
}

.book-demo-main--container {
  padding: 6.25rem 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7.375rem;
  width: 100%;
}

.book-demo-content-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3.5rem;
  width: 100%;
  max-width: 37.625rem;
  margin-top: 2rem;
}

.book-demo--content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  justify-content: flex-start;
}

.book-demo--content span {
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  background: #62c7a321;
  color: #5bc0b3;
  font-family: var(--default-font-family-Dm-Sans);
  font-weight: 400;
  font-size: var(--default-font-size-16);
  line-height: 1.5rem;
  text-align: center;
}

.book-demo--content .book-demo--title {
  color: var(--color-white);
  font-family: var(--default-font-family-Syne);
  font-weight: 600;
  font-size: 3rem;
  line-height: 4.625rem;
  margin-bottom: 0;
}

.book-demo-content-section p {
  margin-bottom: 0;
  color: var(--color-white);
  font-family: var(--default-font-family-Dm-Sans);
  font-weight: 400;
  font-size: var(--default-font-size-18);
  line-height: 1.688rem;
}

.book-demo--form-section {
  width: 100%;
  max-width: 30.938rem;
  border-radius: 1.25rem;
  border: 2px solid var(--color-gray-200);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 1.688rem;
  padding: 2.5rem;
}

.book-demo--form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  max-width: 25.938rem;
}

.book-demo--input-fields {
  display: flex;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
}

.book-demo--input-fields .demo--input-field {
  background-color: var(--color-white);
  border-radius: 0.5rem;
  border: 1px solid var(--color-gray-200);
}

.book-demo--dropdown {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.book-demo--dropdown--label {
  color: var(--color-black);
  font-family: var(--default-font-family-Dm-Sans);
  font-weight: 400;
  font-size: var(--default-font-size-16);
  line-height: 1.5rem;
}

.book-demo--dropdown-field {
  padding: 1rem;
  height: 3.5rem;
  color: var(--color-gray-600);
  font-family: var(--default-font-family-Dm-Sans);
  font-weight: 400;
  line-height: 1.5rem;
  font-size: var(--default-font-size-16);
}

.book-demo--button {
  font-weight: 700;
  font-family: var(--default-font-family-Dm-Sans);
  line-height: 1.5rem;
  font-size: var(--default-font-size-16);
  color: var(--color-black) !important;
  margin-top: 1rem;
}

@media (max-width: 1072px) {
  .book-demo-main--container {
    flex-direction: column;
    padding: 4rem 2rem;
    gap: 3rem;
  }

  .book-demo-content-section {
    align-items: center;
    text-align: center;
  }

  .book-demo--content {
    align-items: center;
  }
}