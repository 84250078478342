.reset-password-page {
  padding: 2.5rem;
  font-family: var(--default-font-family-Dm-Sans);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  background: var(--gradient-green-black);
}


.reset-heading {
  color: var(--color-white) !important;
  font-size: 3rem;
  font-family: var(--default-font-family-Syne);
  line-height: 3.5rem;
}


.reset-password-page .rp-right {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset-password-page .rp-left {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 0 0 0 2rem !important;
}


.reset-password-page .log-in {
  width: 100%;
  margin-top: 1rem;
}

.reset-password-error {
  color: red !important;
  font-size: 14px;
}

.reset-password-success {
  color: green !important;
  font-size: 14px;
}


.gradient-color-background-right {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* background: url(@assets/images/atticus/backgrounds/auth/auth-right-background.svg); */
  background-position: bottom right;
  background-repeat: no-repeat;
}


.reset-password-page .rp-form-container {
  width: 100%;
  max-width: 28.125rem;
  min-width: 21.875rem;
  padding: 2.5rem;
  background-color: var(--color-white);
  border-radius: 1.25rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.rp-form-container h1 {
  font-family: var(--default-font-family-Syne);
}

.reset-password-page form {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 0.313rem;
}

.rp-form-container form input {
  border: 1px solid var(--color-gray-200);
}

.rp-form-container form .input-pass {
  margin-bottom: 0.5rem;
}

.forgot-password-page form .third-party-rp-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0.625rem 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--color-gray-200);
  color: var(--color-gray-700);
  font-size: var(--default-font-size-16);
  background-color: white;
  box-shadow: none;
}

.rp-page-button {
  font-family: var(--default-font-family-Dm-Sans);
}

.forgot-password-page form a {
  text-decoration: underline;
  font-weight: bold;
  color: black;
}

.forgot-password-page form p {
  text-align: center;
  color: var(--color-gray-500);
}

.third-party-rp-button p {
  margin-bottom: 0;
}

.rp-form-footer-text {
  margin-top: 1rem;
}

.rp-large-hide {
  display: none;
}

@media screen and (max-width: 45.938rem) {
  .forgot-password-page .rp-left {
    display: none;
  }

  .forgot-password-page .rp-right {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .rp-large-hide {
    display: block;
    width: 100%;
    margin-bottom: 2rem;
  }

}