.atticus-ai-section {
    width: 100%;
    padding: 100px 112px;
    background-color: #f9fafb;
}

.atticus-ai-container {
    max-width: 76rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.atticus-ai-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-left: 1.5rem;
}

.atticus-ai-left-image {
    width: 100%;
    max-width: 28.125rem;
    border-radius: 0.5rem;
}

.atticus-ai-left-image-container {
    position: relative;
}

.atticus-ai-bottom-right-card {
    position: absolute;
    max-width: 19.688rem;
    min-height: 11.464rem;
    width: 100%;
    top: 26.125rem;
    left: 12.5rem;
    padding: 1.18625rem 1.58125rem 1.18625rem 1.58125rem;
    gap: 1.265rem;
    border-radius: 0.31625rem;
    background-color: #f9fafb;
    box-shadow: rgba(99, 99, 99, 0.2) 0 0.125rem 0.5rem 0;
}

.atticus-ai-bottom-right-card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
}

.atticus-ai-bottom-right-card-heading h4 {
    font-family: var(--default-font-family-Inter);
    font-size: 0.79125rem;
    font-weight: 500;
    line-height: 0.956875rem;
    text-align: left;
    margin: 0;
}

.atticus-ai-close-icon {
    height: 1rem;
    width: 1rem;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.atticus-ai-left-center-card {
    position: absolute;
    background-color: var(--color-white);
    width: 10rem;
    top: 10.3375rem;
    left: -15%;
    padding: 0.75625rem 1.008125rem 0.75625rem 1.008125rem;
    gap: 0.80625rem;
    border-radius: 0.201875rem;
    box-shadow: rgba(9, 30, 66, 0.25) 0 0.25rem 0.5rem -0.125rem, rgba(9, 30, 66, 0.08) 0 0 0 0.0625rem;
}

.atticus-ai-top-right-card {
    position: absolute;
    min-width: 16.375rem;
    top: 8%;
    left: 63%;
    background: var(--color-white);
    padding: 0.97875rem;
    gap: 0.97875rem;
    border-radius: 0.489375rem;
    box-shadow: rgba(9, 30, 66, 0.25) 0 0.25rem 0.5rem -0.125rem, rgba(9, 30, 66, 0.08) 0 0 0 0.0625rem;
}

.atticus-ai-file-container {
    display: flex;
    align-items: center;
    padding: 0.326rem;
    border: 0.0575rem dashed var(--color-gray-200);
    border-radius: 0.244rem;
    gap: 0.5rem;
}

.atticus-ai-top-right-card-text {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: 0.570625rem;
    font-weight: 400;
    line-height: 0.815625rem;
    letter-spacing: 0.017312rem;
    text-align: left;
}

.atticus-ai-summarize-button {
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    width: 4.28875rem;
    height: 1.305rem;
    padding: 0.1875rem;
    gap: 0.32625rem;
    margin-left: 0.625rem;
    border-radius: 0.32625rem;
    border: 0.040625rem solid var(--color-green);
    font-family: var(--default-font-family-Dm-Sans);
    font-size: 0.489rem;
    font-weight: 500;
    line-height: 0.73375rem;
    text-align: center;
    color: var(--color-gray-900);
}

.atticus-ai-summarize-button img {
    width: 0.662rem;
    height: 0.751rem;
}

.atticus-ai-data-driven-container span {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: 0.403125rem;
    font-weight: 400;
    line-height: 0.605rem;
    text-align: left;
    color: var(--Grays-700, #344054);
}

.atticus-ai-data-driven {
    display: flex;
    gap: 0.9375rem;
    align-items: center;
}
.atticus-ai-data-driven .atticus-motion-icons {
    width: 9.68px;
    height: 9.68px;
}

.atticus-ai-data-driven-container {
    display: flex;
    flex-direction: column;
    gap: 0.3025rem;
}

.atticus-ai-data-driven-header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.atticus-ai-data-driven-header-container h4 {
    font-family: Inter;
    font-size: 0.50375rem;
    font-weight: 500;
    line-height: 0.61rem;
    text-align: left;
    margin: 0;
    padding: 0;
}

.atticus-ai-bottom-right-card-body textarea {
    max-width: 16.525rem;
    width: 100%;
    min-height: 3.558125rem;
    padding: 0.6325rem;
    gap: 0.395625rem;
    border-radius: 0.31625rem;
    border: 0.039375rem solid var(--color-gray-200);
    font-family: var(--default-font-family-Dm-Sans);
    font-size: 0.6325rem;
    font-weight: 400;
    line-height: 0.94875rem;
    text-align: left;
    outline: none;
    transition: outline-color 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 0.1rem;
}

.atticus-ai-bottom-right-card-body textarea:focus {
    outline: 0.125rem solid var(--color-grey-400);
    box-shadow: 0 0 0 0.25rem rgba(94, 82, 82, 0.2);
}

.atticus-ai-bottom-right-card-body textarea::placeholder {
    color: var(--color-grey-400);
    font-family: DM Sans;
    font-size: 0.6325rem;
    font-weight: 400;
    line-height: 0.94875rem;
    text-align: left;
}

.atticus-ai-right-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 36.875rem;
    height: 30.313rem;
}

.atticus-ai-title-section {
    font-family: var(--default-font-family-Syne);
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.4rem;
    color: var(--color-gray-900);
}

.atticus-ai-paragrapgh-content {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-16);
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--color-gray-600);
}

.atticus-ai-paragrapgh-content strong {
    font-weight: 400;
    color: var(--color-black);
}

.atticus-ai-features-data {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.feature-text {
    font-family: var(--default-font-family);
    font-weight: 400;
    font-size: var(--default-font-size-16);
    line-height: 1.5rem;
    color: var(--color-gray-700);
}

.atticus-ai-button-section {
    display: flex;
}

.atticus-ai-button-section Button:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.375rem;
    font-family: var(--default-font-family);
    font-size: var(--default-font-size-14);
    color: var(--color-white);
    text-align: center;
    border-radius: 0.5rem;
    line-height: 1.25rem;
    padding: 0 1.25rem 0;
    font-weight: 600;
    height: 3.5rem;
    cursor: default;
}

.atticus-ai-button-section Button:nth-child(1) img {
    width: 1rem;
    height: 1rem;
}

.atticus-ai-button-section Button:nth-child(2) {
    background: linear-gradient(90deg, #7DE264 0%, #59BEB5 100%);
    font-family: var(--default-font-family);
    font-weight: 600;
    font-size: var(--default-font-size-14);
    line-height: 1.25rem;
    text-align: center;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: default;
}

@media (max-width: 89.375rem) {
    .atticus-ai-section {
        padding-left: 5%;
        padding-right: 5%;
    }

    .atticus-ai-left-center-card {
        max-width: 10rem;
        top: 10.3375rem;
        left: -5%;
    }

    .atticus-ai-top-right-card {
        left: 55%;
    }

    .atticus-ai-bottom-right-card {
        left: 45%;
    }
}

@media (max-width: 79.125rem) {
    .atticus-ai-left-center-card {
        max-width: 10rem;
        top: 10.3375rem;
        left: -5%;
    }

    .atticus-ai-top-right-card {
        left: 42%;
    }

    .atticus-ai-bottom-right-card {
        left: 30%;
        bottom: 3%;
    }
}

@media (max-width: 75rem) {
    .atticus-ai-left-center-card {
        max-width: 10rem;
        top: 10.3375rem;
        left: -5%;
    }

    .atticus-ai-top-right-card {
        left: 40%;
    }

    .atticus-ai-bottom-right-card {
        top: 73%;
        left: 28%;
    }
}

@media (max-width: 72.5rem) {
    .atticus-ai-content {
        flex-direction: column;
        max-width: 470px;
        gap: 5rem;
        justify-content: center;
        margin: auto;
    }
}

@media (max-width: 48rem) {
    .left-image img {
        max-width: 100%;
        margin-bottom: 2rem;
    }

    .legal-assistant-right-text {
        padding-left: 0;
        text-align: center;
    }
}

@media (max-width: 33.75rem) {
    .atticus-ai-left-center-card {
        top: 28%;
        left: 0%;
    }

    .atticus-ai-top-right-card {
        left: 20%;
        right: 1%;
    }

    .atticus-ai-bottom-right-card {
        top: 73%;
        right: 1%;
        left: 0%;
    }
}

@media (max-width: 26.25rem) {
    .atticus-ai-left-center-card {
        top: 24%;
        left: 0%;
    }

    .atticus-ai-top-right-card {
        top: 2%;
        left: 0%;
        right: 1%;
    }

    .atticus-ai-bottom-right-card {
        top: 73%;
        right: 1%;
        left: 0%;
    }

    .atticus-ai-content {
        max-width: 400px;
        gap: 8rem;
    }
}

@media (max-width: 23.438rem) {
    .atticus-ai-left-center-card {
        top: 16%;
        left: 0%;
    }

    .atticus-ai-top-right-card {
        top: -15%;
        left: -3%;
        right: 1%;
    }

    .atticus-ai-content {
        max-width: 25rem;
        gap: 10rem;
    }
}