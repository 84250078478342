.firms-container {
  background: url('@assets/images/backgrounds/left-ellips.svg') no-repeat,
    url('@assets/images/backgrounds/right-ellips.svg') no-repeat,
    var(--gradient-dark-overlay);
  background-repeat: no-repeat;
  background-size: contain, contain, cover;
  background-position: top left, bottom right, center;
  ;
}

.pricing-card-header-text {
  width: 100%;
  max-width: 51rem;
}

.pricing-plan-main-container {
  position: relative;
}

.pricing-cards-container {
  display: flex;
  justify-content: center;
  gap: 28rem;
  position: relative;
  flex-wrap: wrap;
}


@media (max-width: 1150px) {
  .pricing-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .pricing-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}