.chapter-ai-section {
    width: 100%;
    padding: 6.25rem 7rem;
    background-color: #f9fafb;
}

.chapter-ai-container {
    max-width: 76rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.chapter-ai-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-left: 1.5rem;
    gap: 64px;
}

.chapter-ai-left-image-container {
    position: relative;
}

.chapter-ai-left-image {
    width: 100%;
    max-width: 28.125rem;
    border-radius: 0.5rem;
}

.chapter-ai-top-right-card {
    position: absolute;
    min-width: 21.125rem;
    top: 8%;
    left: 34%;
    background: var(--color-white);
    padding: 0.97875rem;
    gap: 0.97875rem;
    border-radius: 0.489375rem;
    box-shadow: rgba(9, 30, 66, 0.25) 0 0.25rem 0.5rem -0.125rem, rgba(9, 30, 66, 0.08) 0 0 0 0.0625rem;
}

.chapter-ai-top-right-card-content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.chapter-ai-top-right-card-content h3 {
    color: #060D11;
    font-family: var(--default-font-family-Figtree);
    font-weight: 600;
    font-size: var(--default-font-size-16);
    line-height: 1.2rem;
    margin-bottom: 0;
}

.chapter-ai-top-right-card-content p {
    color: #4A4A4A;
    font-family: var(--default-font-family-Figtree);
    font-weight: 400;
    font-size: var(--default-font-size-14);
    line-height: 1.225rem;
}

.chapter-filing-button {
    background: linear-gradient(90deg, #2C3740 0%, #060D11 100%);
    color: var(--color-white);
    font-family: var(--default-font-family-Figtree);
    font-weight: 600;
    font-size: var(--default-font-size-14);
    line-height: 1.25rem;
    height: 3.25rem;
    border-radius: 1rem;
}

.chapter-ai-bottom-right-card {
    position: absolute;
    width: 100%;
    max-width: 19.688rem;
    top: 26.438rem;
    left: -65px;
    padding: 1.186rem 1.581rem;
    gap: 1.265rem;
    border-radius: 0.31625rem;
    background-color: #f9fafb;
    box-shadow: 0px 4px 4px -4px rgba(12, 12, 13, 0.05), 0px 16px 16px -8px rgba(12, 12, 13, 0.1);
}

.bottom-right-card-checklist-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chapter-ai-bottom-right-card-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
}

.chapter-ai-bottom-right-card-content h4 {
    font-family: var(--default-font-family-Figtree);
    font-weight: 600;
    font-size: var(--default-font-size-16);
    line-height: 1.2rem;
    color: #060D11;
    margin-bottom: 0;
}

.chapter-ai-bottom-right-card-content p {
    font-family: var(--default-font-family-Figtree);
    font-weight: 400;
    font-size: var(--default-font-size-14);
    line-height: 1.225rem;
    text-align: center;
    color: #4A4A4A;
    margin-bottom: 0;
}

.chapter-ai-right-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 590px;
}

.chapter-ai-title-section {
    font-family: var(--default-font-family-Syne);
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.4rem;
    color: var(--color-gray-900);
}

.chapter-ai-paragrapgh-content {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-16);
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--color-gray-600);
}

.chapter-ai-paragrapgh-content strong {
    font-weight: 400;
    color: var(--color-black);
}

.chapter-ai-features-data {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.feature-text {
    font-family: var(--default-font-family);
    font-weight: 400;
    font-size: var(--default-font-size-16);
    line-height: 1.5rem;
    color: var(--color-gray-700);
}

.chapter-ai-button-section {
    display: flex;
}

.chapter-ai-button-section Button:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.375rem;
    font-family: var(--default-font-family);
    font-size: var(--default-font-size-14);
    color: var(--color-white);
    text-align: center;
    border-radius: 0.5rem;
    line-height: 1.25rem;
    padding: 0 1.25rem 0;
    font-weight: 600;
    height: 3.5rem;
    background: linear-gradient(180deg, #101010 66%, #1D272E 100%);
    cursor: default;
}

.chapter-ai-button-section Button:nth-child(1) img {
    width: 1rem;
    height: 1rem;
}

.chapter-ai-button-section Button:nth-child(2) {
    background: linear-gradient(180deg, #101010 66%, #1D272E 100%);
    font-family: var(--default-font-family);
    font-weight: 600;
    font-size: var(--default-font-size-14);
    line-height: 1.25rem;
    text-align: center;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: default;
}


@media (max-width: 1200px) {
    .chapter-ai-section {
        padding: 4rem 3rem;
    }

    .chapter-ai-container {
        flex-direction: column;
        align-items: center;
    }

    .chapter-ai-content {
        flex-direction: column;
        margin-left: 0;
        gap: 0;
    }

    .chapter-ai-right-content {
        width: 100%;
        text-align: center;
        gap: 1rem;
    }

    .chapter-ai-title-section {
        font-size: 1.75rem;
        line-height: 2.2rem;
    }

    .chapter-ai-bottom-right-card {
        top: -7.813rem;
        left: -7.813rem;
        margin: auto;
        position: relative;
    }
}

@media (max-width: 768px) {
    .chapter-ai-section {
        padding: 3rem 1.5rem;
    }

    .chapter-ai-left-image {
        max-width: 16rem;
    }

    .chapter-ai-title-section {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .chapter-ai-bottom-right-card {
        max-width: 100%;
        padding: 1rem;
    }
}

@media (max-width: 480px) {
    .chapter-ai-container {
        padding: 0 1rem;
    }

    .chapter-ai-content {
        flex-direction: column;
        align-items: center;
    }

    .chapter-ai-left-image {
        max-width: 14rem;
    }

    .chapter-ai-title-section {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    .chapter-ai-bottom-right-card {
        padding: 0.75rem;
    }

    .chapter-filing-button {
        font-size: var(--default-font-size-12);
        height: 2.5rem;
    }
}
